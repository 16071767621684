import {
  Avatar,
  Box,
  Confirm,
  ContentContainer,
  EmptyState,
  IconButton,
  InnerContentContainer,
  InnerPageContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TablePagination,
  TablePlaceholderRows,
  TableRow,
  Text,
  Tooltip,
  getAvatarVariantFromString,
  paginatorOptions,
  parseCurrency,
  parseTimestamp,
  useConfirm,
} from '@orbiapp/components';
import React from 'react';
import { Navigate } from 'react-router-dom';

import { useDataGridPagination } from '../../../../../helpers';
import {
  MembershipPurchaseListItem,
  membershipPurchasesSortableKeys,
} from '../../../../../models';
import {
  MembershipPurchaseListItemsSelector,
  MembershipTypeSelector,
  OrbiPaySettingsSelector,
  RefundMembershipPurchaseSelector,
  getMembershipPurchaseListItemsThunk,
  membershipsActions,
  refundMembershipPurchaseThunk,
  useDispatch,
  useSelector,
} from '../../../../../store';

const membershipPurchasesEmptyState = (
  <EmptyState titleTx="label.memberships.view-membership.purchases.empty-state.title" />
);

const TABLE_COLUMN_WIDTHS = {
  fullName: 200,
  membershipPeriodName: 200,
  purchasedAt: 200,
  refundedAt: 200,
  price: 200,
  actions: 60,
};

function MembershipPurchasesTable() {
  const membershipPurchasesListItemsPagination = useSelector(
    MembershipPurchaseListItemsSelector.selectPagination,
  );
  const membershipPurchasesListItemsMembers = useSelector(
    MembershipPurchaseListItemsSelector.selectAll,
  );
  const membershipPurchasesListItemsStatus = useSelector(
    MembershipPurchaseListItemsSelector.selectStatus,
  );

  const refundMembershipPurchaseStatus = useSelector(
    RefundMembershipPurchaseSelector.selectStatus,
  );

  const currency = useSelector(OrbiPaySettingsSelector.selectCurrency);

  const dispatch = useDispatch();

  const { confirmValue, closeConfirm, isOpen, openConfirm } =
    useConfirm<string>();

  const isLoading = membershipPurchasesListItemsStatus === 'pending';

  const { rows, paginatorProps, onPageSizeChange, onPaginate, onSort } =
    useDataGridPagination({
      data: membershipPurchasesListItemsMembers,
      pagination: membershipPurchasesListItemsPagination,
      reset: membershipsActions.clearMembershipPurchaseListItems,
      thunk: getMembershipPurchaseListItemsThunk,
    });

  if (!rows.length && isLoading) {
    return (
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead tx="label.memberships.view-membership.purchases.full-name" />
            <TableHead tx="label.memberships.view-membership.purchases.period-name" />
            <TableHead tx="label.memberships.view-membership.purchases.purchased-at" />
            <TableHead tx="label.memberships.view-membership.purchases.refunded-at" />
            <TableHead tx="label.memberships.view-membership.purchases.price" />
            <TableHead fixedRight />
          </TableRow>
        </TableHeader>
        <TableBody>
          <TablePlaceholderRows
            rowCount={10}
            layout={Object.values(TABLE_COLUMN_WIDTHS)}
          />
        </TableBody>
      </Table>
    );
  }

  if (!rows.length) {
    return membershipPurchasesEmptyState;
  }

  const refundMembershipPurchase = async () => {
    if (!confirmValue) return;

    await dispatch(refundMembershipPurchaseThunk(confirmValue));

    closeConfirm();
  };

  const renderMembershipPurchaseListItemTableRow = (
    membershipPurchaseListItem: MembershipPurchaseListItem,
  ) => {
    const handleRefundClick = () => {
      openConfirm(membershipPurchaseListItem.membershipPurchaseKey);
    };

    return (
      <TableRow key={membershipPurchaseListItem.membershipPurchaseKey}>
        <TableCell width={TABLE_COLUMN_WIDTHS.fullName}>
          <Avatar
            fallbackLetter={membershipPurchaseListItem.fullName.charAt(0)}
            minWidth={40}
            src={
              membershipPurchaseListItem.profilePicture?.thumbnail64.url ??
              undefined
            }
            variant={
              membershipPurchaseListItem.userKey
                ? getAvatarVariantFromString(membershipPurchaseListItem.userKey)
                : undefined
            }
          />
          <Text
            ml={8}
            variant="bodySm"
            text={membershipPurchaseListItem.fullName}
            as="span"
          />
        </TableCell>

        <TableCell
          width={TABLE_COLUMN_WIDTHS.membershipPeriodName}
          text={membershipPurchaseListItem.membershipPeriodName}
        />
        <TableCell
          width={200}
          text={parseTimestamp(
            membershipPurchaseListItem.purchasedAt,
            'DD MMM YYYY HH:mm',
          )}
        />
        <TableCell
          width={TABLE_COLUMN_WIDTHS.refundedAt}
          text={
            membershipPurchaseListItem.refundedAt
              ? parseTimestamp(
                  membershipPurchaseListItem.refundedAt,
                  'DD MMM YYYY HH:mm',
                )
              : ''
          }
        />
        <TableCell
          width={TABLE_COLUMN_WIDTHS.price}
          text={parseCurrency(membershipPurchaseListItem.price, currency)}
        />

        <TableCell fixedRight hoverCell width={TABLE_COLUMN_WIDTHS.actions}>
          {!membershipPurchaseListItem.refundedAt && (
            <Box ml="auto">
              <Tooltip
                placement="left"
                titleTx="label.memberships.view-membership.purchases.refund"
              >
                <IconButton
                  icon="receipt-refund-outline"
                  onClick={handleRefundClick}
                />
              </Tooltip>
            </Box>
          )}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <React.Fragment>
      <Table>
        <TableHeader
          onSort={onSort}
          orderBy={membershipPurchasesListItemsPagination.orderBy}
          sortableColumns={Object.values(membershipPurchasesSortableKeys)}
          sortOrder={membershipPurchasesListItemsPagination.sortOrder}
        >
          <TableRow>
            <TableHead
              tx="label.memberships.view-membership.purchases.full-name"
              name={membershipPurchasesSortableKeys.fullName}
            />
            <TableHead tx="label.memberships.view-membership.purchases.period-name" />
            <TableHead
              tx="label.memberships.view-membership.purchases.purchased-at"
              name={membershipPurchasesSortableKeys.purchasedAt}
            />
            <TableHead tx="label.memberships.view-membership.purchases.refunded-at" />
            <TableHead tx="label.memberships.view-membership.purchases.price" />

            <TableHead fixedRight />
          </TableRow>
        </TableHeader>
        <TableBody>
          {rows.map(renderMembershipPurchaseListItemTableRow)}
        </TableBody>
      </Table>

      <TablePagination
        currentPage={paginatorProps.currentPage}
        hasNextPage={paginatorProps.hasNextPage}
        hasPrevPage={paginatorProps.hasPrevPage}
        onPageSizeChange={onPageSizeChange}
        onPaginate={onPaginate}
        pageSize={paginatorProps.pageSize}
        paginatorOptions={paginatorOptions}
        tx="label.general.rows-per-page"
      />

      <Confirm
        cancelTx="prompt.refund-membership-purchase.cancel"
        confirmTx="prompt.refund-membership-purchase.confirm"
        isLoading={refundMembershipPurchaseStatus === 'pending'}
        isOpen={isOpen}
        messageTx="prompt.refund-membership-purchase.message"
        onCancel={closeConfirm}
        onConfirm={refundMembershipPurchase}
        titleTx="prompt.refund-membership-purchase.title"
      />
    </React.Fragment>
  );
}

export function ViewMembershipPurchases() {
  const isIntegrated = useSelector(MembershipTypeSelector.selectIsIntegrated);

  if (isIntegrated) {
    return <Navigate to="overview" />;
  }

  return (
    <InnerPageContainer>
      <ContentContainer>
        <InnerContentContainer>
          <Text
            color="pageTitle"
            as="h1"
            tx="label.memberships.view-membership.tabs.membership-purchases"
            variant="titleMd"
          />

          <MembershipPurchasesTable />
        </InnerContentContainer>
      </ContentContainer>
    </InnerPageContainer>
  );
}
