import { StatsigEnv } from '@orbiapp/components';

const env_vars = {
  API_URL: import.meta.env.VITE_APP_API_URL,

  COMPANY_DASHBOARD_URL: import.meta.env.VITE_APP_COMPANY_DASHBOARD_URL,

  FIREBASE_API_KEY: import.meta.env.VITE_APP_FIREBASE_API_KEY,
  FIREBASE_APP_ID: import.meta.env.VITE_APP_FIREBASE_APP_ID,
  FIREBASE_AUTH_DOMAIN: import.meta.env.VITE_APP_FIREBASE_AUTH_DOMAIN,
  FIREBASE_MEASUREMENT_ID: import.meta.env.VITE_APP_FIREBASE_MEASUREMENT_ID,
  FIREBASE_PROJECT_ID: import.meta.env.VITE_APP_FIREBASE_PROJECT_ID,

  DYNAMIC_LINK_PATH: 'link.orbiapp.io',
  ENV: 'production',
  ANDROID_PACKAGE_NAME: 'com.campusmobile',
  IOS_BUNDLE_ID: 'com.dipitytechnologies.campusappen',

  STATSIG_SDK_KEY: import.meta.env.VITE_APP_STATSIG_SDK_KEY,
};

if (env_vars.FIREBASE_PROJECT_ID === 'orbi-testing') {
  env_vars.DYNAMIC_LINK_PATH = 'testing.link.orbiapp.io';
  env_vars.ENV = 'testing';
  env_vars.ANDROID_PACKAGE_NAME = 'com.campusmobile';
  env_vars.IOS_BUNDLE_ID = 'io.orbiapp.orbi.testing';
}

if (!import.meta.env.PROD) {
  Object.keys(env_vars).forEach((key) => {
    if (env_vars[key as keyof typeof env_vars] === undefined) {
      throw new Error(
        `Error. Value of ${key} must be set in order for application to run properly.`,
      );
    }
  });
}

export const firebaseConfig = {
  apiKey: env_vars.FIREBASE_API_KEY,
  authDomain: env_vars.FIREBASE_AUTH_DOMAIN,
  projectId: env_vars.FIREBASE_PROJECT_ID,
  appId: env_vars.FIREBASE_APP_ID,
  measurementId: env_vars.FIREBASE_MEASUREMENT_ID,
  dynamicLinkPath: env_vars.DYNAMIC_LINK_PATH,
};

export const ENV = env_vars.ENV as 'testing' | 'production';

export const AUTH_URL = env_vars.API_URL + '/auth';
export const API_URL = env_vars.API_URL + '/student-dashboard';

export const HOTJAR_ID = 3043557;
export const HOTJAR_VERSION_NUMBER = 6;

export const IOS_APP_STORE_ID = '1226346650';
export const IOS_BUNDLE_ID = env_vars.IOS_BUNDLE_ID;
export const ANDROID_PACKAGE_NAME = env_vars.ANDROID_PACKAGE_NAME;

export const COMPANY_DASHBOARD_URL = env_vars.COMPANY_DASHBOARD_URL;

export const STATSIG_SDK_KEY = env_vars.STATSIG_SDK_KEY;
export const STATSIG_ENV: StatsigEnv =
  ENV === 'production' ? 'production' : 'staging';

export const BRANCH_IO_LIVE_KEY = 'key_live_euhTFFUIqKf2TRFYJm5EeafdqqlzkrWq';
