import { GeneralApiResultWithData } from '@orbiapp/components';

import {
  ActivityListItem,
  ActivityRecord,
  UpdateActivityErrorResponse,
  UpdateActivityForm,
  UpdateActivityResponse,
} from '../../../../../models';
import { Logger } from '../../../../logger';
import { apisauce } from '../../../api';
import { CUSTOM_TIMEOUTS } from '../../../api.constants';
import { getGeneralApiProblem } from '../../../api.utils';
import { UpdateActivityResponseDto } from './activities.dto';
import {
  activityRecordMapper,
  updateActivityMapper,
} from './activities.mappers';

export const updateActivity = async (
  updateActivityForm: UpdateActivityForm,
): Promise<
  GeneralApiResultWithData<UpdateActivityResponse, UpdateActivityErrorResponse>
> => {
  const res = await apisauce.put<
    UpdateActivityResponseDto,
    UpdateActivityErrorResponse
  >(
    `/v2/activities/${updateActivityForm.activityKey}`,
    updateActivityMapper(updateActivityForm),
    { timeout: CUSTOM_TIMEOUTS.updateActivity },
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data: UpdateActivityResponse = {
      coverImage: res.data.coverImage,
    };

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('updateActivity', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const getActivityRecords = async (): Promise<
  GeneralApiResultWithData<ActivityRecord[]>
> => {
  const res = await apisauce.get<ActivityListItem[], any>(
    '/v2/activities/listed/items',
    {
      orderBy: 'endDate',
      limit: 25,
      offset: 0,
      sortOrder: 'desc',
    },
    { timeout: CUSTOM_TIMEOUTS.getActivityRecords },
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = res.data
      .filter((activityListItem) => activityListItem.endDate > Date.now())
      .map(activityRecordMapper);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getActivityRecords', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};
