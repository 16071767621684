import { activities } from './activities';
import { auth } from './auth';
import { countries } from './countries';
import { department } from './department';
import { locations } from './locations';
import { offers } from './offers';
import { orbiPay } from './orbi-pay';
import { questions } from './questions';
import { referrals } from './referrals';
import { search } from './search';

export const v1 = {
  activities,
  auth,
  department,
  orbiPay,
  questions,
  referrals,
  search,
  offers,
  locations,
  countries,
};
