import {
  DEEP_LINK_BASE_URL,
  EditMeta,
  EditedByMeta,
  Pagination,
  formatPublishDate,
} from '@orbiapp/components';
import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  CreateOfferForm,
  Offer,
  OfferCategory,
  OffersOrderByKey,
  PartialOffer,
  UpdateOfferForm,
} from '../../models';
import { Logger, OrbiApi, getPageAndNextPage, v1 } from '../../services';
import { branchIO } from '../../services/branch';
import { setAlert } from '../global-ui-state';
import { ThunkApiConfig } from '../store.types';
import { partialOffersAdapter } from './offers.adapter';

export const createOfferThunk = createAsyncThunk<
  PartialOffer,
  CreateOfferForm,
  ThunkApiConfig
>('offers/create-offer', async (createOfferForm, thunkAPI) => {
  const state = thunkAPI.getState();
  if (!state.account.account.data) {
    return thunkAPI.rejectWithValue({ kind: 'bad-request' });
  }

  const res = await OrbiApi.call(v1.offers.createOffer, createOfferForm);

  if (res.kind === 'ok') {
    thunkAPI.dispatch(
      setAlert('publish-offer:success', {
        publishDate: formatPublishDate(createOfferForm.startDate).toLowerCase(),
      }),
    );

    const editedByMeta: EditedByMeta = {
      firstName: state.account.account.data.firstName,
      lastName: state.account.account.data.lastName,
      profilePicture: state.account.account.data.profilePicture,
      userKey: state.account.account.data.userKey,
    };
    const now = Date.now();
    const offer: PartialOffer = {
      contactName: createOfferForm.contactName,
      disabledAt: null,
      editMeta: {
        createdAt: now,
        createdBy: editedByMeta,
        updatedAt: now,
        updatedBy: editedByMeta,
      },
      endDate: createOfferForm.endDate,
      offerKey: res.data.offerKey,
      startDate: createOfferForm.startDate,
      title: createOfferForm.title,
    };
    return offer;
  }

  thunkAPI.dispatch(setAlert('publish-offer:error'));
  return thunkAPI.rejectWithValue(res);
});

export const updateOfferThunk = createAsyncThunk<
  PartialOffer,
  UpdateOfferForm,
  ThunkApiConfig
>('offers/update-offer', async (updateOfferForm, thunkAPI) => {
  const state = thunkAPI.getState();
  if (!state.offers.offer.data?.offerKey || !state.account.account.data) {
    thunkAPI.dispatch(setAlert('update-offer:error'));
    return thunkAPI.rejectWithValue({ kind: 'bad-request' });
  }

  const res = await OrbiApi.call(v1.offers.updateOffer, {
    updateOffer: updateOfferForm,
    offerKey: state.offers.offer.data.offerKey,
  });

  if (res.kind === 'ok') {
    thunkAPI.dispatch(setAlert('update-offer:success'));

    const editedByMeta: EditedByMeta = {
      firstName: state.account.account.data.firstName,
      lastName: state.account.account.data.lastName,
      profilePicture: state.account.account.data.profilePicture,
      userKey: state.account.account.data.userKey,
    };
    const now = Date.now();
    const offer: PartialOffer = {
      contactName: updateOfferForm.contactName,
      disabledAt: null,
      editMeta: {
        createdAt: now,
        createdBy: editedByMeta,
        updatedAt: now,
        updatedBy: editedByMeta,
      },
      endDate: updateOfferForm.endDate,
      offerKey: state.offers.offer.data.offerKey,
      startDate: updateOfferForm.startDate,
      title: updateOfferForm.title,
    };

    return offer;
  }

  thunkAPI.dispatch(setAlert('update-offer:error'));
  return thunkAPI.rejectWithValue(res);
});

export const getOffersThunk = createAsyncThunk<
  PartialOffer[],
  Pagination<OffersOrderByKey>,
  ThunkApiConfig
>('offers/get-offers', async (pagination, thunkAPI) => {
  const state = thunkAPI.getState();

  const search = state.offers.offers.search;
  if (search) {
    const res = await OrbiApi.call(v1.offers.getOffers, { pagination, search });

    if (res.kind === 'ok') {
      return res.data;
    }

    return thunkAPI.rejectWithValue(res);
  }

  const [currentPage, nextPage] = await getPageAndNextPage(
    partialOffersAdapter.getSelectors().selectAll(state.offers.offers.data),

    v1.offers.getOffers,
    { pagination, search: undefined },
  );

  if (currentPage.kind !== 'ok') return thunkAPI.rejectWithValue(currentPage);
  if (nextPage.kind !== 'ok') return thunkAPI.rejectWithValue(nextPage);

  return [...currentPage.data, ...nextPage.data];
});

export const getOfferThunk = createAsyncThunk<Offer, string, ThunkApiConfig>(
  'offers/get-offer',
  async (offerKey, thunkAPI) => {
    const res = await OrbiApi.call(v1.offers.getOffer, offerKey);

    if (res.kind === 'ok') {
      return res.data;
    }

    return thunkAPI.rejectWithValue(res);
  },
);

export const getOfferCategoriesThunk = createAsyncThunk<
  OfferCategory[],
  undefined,
  ThunkApiConfig
>('offers/get-offer-categories', async (_, thunkAPI) => {
  const res = await OrbiApi.call(v1.offers.getOfferCategories, undefined);

  if (res.kind === 'ok') {
    return res.data;
  }

  return thunkAPI.rejectWithValue(res);
});

export const toggleIsDisabledThunk = createAsyncThunk<
  {
    disabledAt: number | null;
    editMeta: EditMeta;
    offerKey: string;
  },
  string,
  ThunkApiConfig
>('offers/toggle-is-disabled', async (offerKey, thunkAPI) => {
  const state = thunkAPI.getState();
  if (!state.account.account.data) {
    return thunkAPI.rejectWithValue({ kind: 'bad-request' });
  }

  const offer =
    state.offers.offer.data ?? state.offers.offers.data.entities[offerKey];

  const isDisabled = !Boolean(offer.disabledAt);

  const res = await OrbiApi.call(v1.offers.toggleIsDisabled, {
    offerKey,
    isDisabled,
  });

  if (res.kind === 'ok') {
    const editMeta: EditMeta = {
      updatedBy: {
        firstName: state.account.account.data.firstName,
        lastName: state.account.account.data.lastName,
        profilePicture: state.account.account.data.profilePicture,
        userKey: state.account.account.data.userKey,
      },
      updatedAt: Date.now(),
      createdAt: offer.editMeta.createdAt,
      createdBy: offer.editMeta.createdBy,
    };

    return {
      editMeta,
      disabledAt: isDisabled ? editMeta.updatedAt : null,
      offerKey,
    };
  }

  return thunkAPI.rejectWithValue(res);
});

export const getOffersLinkThunk = createAsyncThunk<
  string,
  undefined,
  ThunkApiConfig
>('offers/get-offers-link', async (_, thunkAPI) => {
  const state = thunkAPI.getState();
  const departmentKey = state.department.department.data?.departmentKey;

  if (!departmentKey) {
    return thunkAPI.rejectWithValue({ kind: 'bad-request' });
  }

  if (state.offers.offersLink.data) {
    return state.offers.offersLink.data;
  }

  try {
    const link = await branchIO.createLink({
      data: {
        $canonical_url: `${DEEP_LINK_BASE_URL}/offers/${departmentKey}/department`,
      },
    });

    return link;
  } catch (err) {
    if (err instanceof Error) {
      Logger.error('Branch IO link error', { err: err.message });
    }
    return thunkAPI.rejectWithValue({ kind: 'bad-request' });
  }
});
