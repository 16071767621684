import {
  Box,
  BreadCrumb,
  BreadCrumbs,
  Button,
  ContentContainer,
  ContentSidebar,
  ContentSidebarContentContainer,
  EmptyState,
  ExpandedQrCodeProvider,
  FileType,
  Icon,
  IconButton,
  ImageSliderModal,
  ImageSliderModalSlide,
  InnerContentContainer,
  InnerPageContainer,
  LeadingInputBox,
  PageContainer,
  PrintQrCode,
  ResponsiveBox,
  SearchInput,
  SolidIconButton,
  Spinner,
  Status,
  StatusProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TablePagination,
  TablePlaceholderRows,
  TableRow,
  Text,
  Toolbar,
  ToolbarContentContainer,
  Tooltip,
  TrailingInputBox,
  UpdatedAtTableCell,
  UpdatedByTableCell,
  paginatorOptions,
  parseTimestamp,
  translate,
  useDebounce,
} from '@orbiapp/components';
import React from 'react';

import { assets } from '../../../../assets';
import { useDataGridPagination, usePauseOffer } from '../../../../helpers';
import {
  OffersOrderByKey,
  PartialOffer,
  offersSortableKeys,
} from '../../../../models';
import {
  DepartmentSelector,
  OffersLinkSelector,
  OffersSelector,
  getOffersLinkThunk,
  getOffersThunk,
  offersActions,
  useDispatch,
  useSelector,
} from '../../../../store';

const TABLE_COLUMN_WIDTHS = {
  title: 200,
  contactName: 200,
  startDate: 200,
  endDate: 200,
  updatedBy: 200,
  updatedAt: 200,
  status: 200,
  actions: 40,
};

function getOfferStatusOptions(options: {
  disabledAt: number | null;
  endDate: number | null;
  startDate: number;
}): StatusProps {
  const now = Date.now();

  if (options.endDate && now > options.endDate) {
    return {
      variant: 'error',
      tx: 'label.offers-status.closed',
    };
  }

  if (options.startDate > now) {
    return {
      variant: 'info',
      tx: 'label.offers-status.scheduled',
    };
  }

  if (options.disabledAt) {
    return {
      variant: 'error',
      tx: 'label.offers-status.paused',
    };
  }

  return {
    variant: 'success',
    tx: 'label.offers-status.live',
  };
}

function SearchOffers() {
  const search = useSelector(OffersSelector.selectSearch);
  const pagination = useSelector(OffersSelector.selectPagination);

  const dispatch = useDispatch();

  const debounce = useDebounce();

  const handleSearch: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    dispatch(offersActions.setOffersSearch(e.target.value));

    debounce(() => {
      if (!e.target.value) {
        dispatch(offersActions.clearOffers());
        dispatch(offersActions.setOffersSearch(''));
      }

      dispatch(getOffersThunk(pagination));
    });
  };

  const cancelSearch = () => {
    dispatch(offersActions.clearOffers());
    dispatch(offersActions.setOffersSearch(''));

    dispatch(getOffersThunk(pagination));
  };

  return (
    <Box width={300}>
      <SearchInput
        onChange={handleSearch}
        value={search}
        placeholderTx="placeholder.search-offers"
        leadingElement={
          <LeadingInputBox>
            <Icon name="magnifying-glass" />
          </LeadingInputBox>
        }
        trailingElement={
          <TrailingInputBox>
            {search.length > 0 && (
              <IconButton icon="x-mark" onClick={cancelSearch} />
            )}
          </TrailingInputBox>
        }
      />
    </Box>
  );
}

function OfferTableRow(props: { partialOffer: PartialOffer }) {
  const { partialOffer } = props;

  const pauseOffer = usePauseOffer({
    endDate: partialOffer.endDate,
    offerKey: partialOffer.offerKey,
    startDate: partialOffer.startDate,
  });

  return (
    <TableRow to={`/offers/${partialOffer.offerKey}`}>
      <TableCell text={partialOffer.title} width={TABLE_COLUMN_WIDTHS.title} />
      <TableCell
        text={partialOffer.contactName}
        width={TABLE_COLUMN_WIDTHS.contactName}
      />
      <TableCell
        text={parseTimestamp(partialOffer.startDate, 'DD MMM YYYY HH:mm')}
        width={TABLE_COLUMN_WIDTHS.startDate}
      />
      <TableCell
        text={
          partialOffer.endDate
            ? parseTimestamp(partialOffer.endDate, 'DD MMM YYYY HH:mm')
            : ''
        }
        width={TABLE_COLUMN_WIDTHS.endDate}
      />

      <TableCell width={TABLE_COLUMN_WIDTHS.status}>
        <Status {...getOfferStatusOptions(partialOffer)} />
      </TableCell>

      <UpdatedByTableCell
        fallbackTx="placeholder.unknown-user"
        editMeta={partialOffer.editMeta}
        width={TABLE_COLUMN_WIDTHS.updatedBy}
      />

      <UpdatedAtTableCell
        editMeta={partialOffer.editMeta}
        width={TABLE_COLUMN_WIDTHS.updatedAt}
      />

      <TableCell width={TABLE_COLUMN_WIDTHS.actions} fixedRight hoverCell>
        <Box ml="auto">
          {!pauseOffer.hidden && (
            <Tooltip
              placement="left"
              titleTx={
                partialOffer.disabledAt
                  ? 'label.tooltip.resume'
                  : 'label.tooltip.pause'
              }
            >
              <IconButton
                isLoading={pauseOffer.isLoading}
                onClick={pauseOffer.toggleIsDisabled}
                icon={
                  partialOffer.disabledAt ? 'play-outline' : 'pause-outline'
                }
              />
            </Tooltip>
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
}

function renderOfferTableRow(partialOffer: PartialOffer) {
  return (
    <OfferTableRow key={partialOffer.offerKey} partialOffer={partialOffer} />
  );
}

function OffersTable() {
  const offersStatus = useSelector(OffersSelector.selectStatus);
  const offers = useSelector(OffersSelector.selectData);
  const pagination = useSelector(OffersSelector.selectPagination);

  const { rows, paginatorProps, onPageSizeChange, onPaginate, onSort } =
    useDataGridPagination<PartialOffer, OffersOrderByKey>({
      data: offers,
      pagination,
      reset: offersActions.clearOffers,
      thunk: getOffersThunk,
    });

  const isLoading = offersStatus === 'pending';

  if (isLoading) {
    return (
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead tx="label.offers.dashboard.table.title" />
            <TableHead tx="label.offers.dashboard.table.contact-person" />
            <TableHead tx="label.offers.dashboard.table.start-date" />
            <TableHead tx="label.offers.dashboard.table.end-date" />
            <TableHead tx="label.offers.dashboard.table.status" />
            <TableHead tx="label.offers.dashboard.table.updated-by" />
            <TableHead tx="label.offers.dashboard.table.updated-at" />
            <TableHead fixedRight />
          </TableRow>
        </TableHeader>

        <TableBody>
          <TablePlaceholderRows
            rowCount={10}
            layout={Object.values(TABLE_COLUMN_WIDTHS)}
          />
        </TableBody>
      </Table>
    );
  }

  const isEmpty = rows.length === 0;

  if (isEmpty) {
    return (
      <EmptyState
        to="/offers/create-offer"
        buttonTx="button.offers.new-offer"
        titleTx="label.offers.dashboard.empty-state"
      />
    );
  }

  return (
    <React.Fragment>
      <Table>
        <TableHeader
          onSort={onSort}
          orderBy={pagination.orderBy}
          sortableColumns={Object.values(offersSortableKeys)}
          sortOrder={pagination.sortOrder}
        >
          <TableRow>
            <TableHead
              tx="label.offers.dashboard.table.title"
              name={offersSortableKeys.title}
            />
            <TableHead
              tx="label.offers.dashboard.table.contact-person"
              name={offersSortableKeys.contactName}
            />
            <TableHead
              tx="label.offers.dashboard.table.start-date"
              name={offersSortableKeys.startDate}
            />
            <TableHead
              tx="label.offers.dashboard.table.end-date"
              name={offersSortableKeys.endDate}
            />

            <TableHead tx="label.offers.dashboard.table.status" />

            <TableHead
              tx="label.offers.dashboard.table.updated-by"
              name={offersSortableKeys.updatedBy}
            />
            <TableHead
              tx="label.offers.dashboard.table.updated-at"
              name={offersSortableKeys.updatedAt}
            />
            <TableHead fixedRight />
          </TableRow>
        </TableHeader>

        <TableBody>{rows.map(renderOfferTableRow)}</TableBody>
      </Table>

      <TablePagination
        currentPage={paginatorProps.currentPage}
        hasNextPage={paginatorProps.hasNextPage}
        hasPrevPage={paginatorProps.hasPrevPage}
        onPageSizeChange={onPageSizeChange}
        onPaginate={onPaginate}
        pageSize={paginatorProps.pageSize}
        paginatorOptions={paginatorOptions}
        tx="label.general.rows-per-page"
      />
    </React.Fragment>
  );
}

function OffersContent() {
  const offersLinkStatus = useSelector(OffersLinkSelector.selectStatus);
  const offersLink = useSelector(OffersLinkSelector.selectData);

  const dispatch = useDispatch();

  const getQrCode = () => {
    dispatch(getOffersLinkThunk());
  };

  const isLoading = offersLinkStatus === 'pending';
  const isOpen = !!offersLink || isLoading;

  return (
    <React.Fragment>
      <Box flex flexWrap="wrap" gap={16} flexJustify="between">
        <Text
          color="pageTitle"
          tx="title.offers.dashboard"
          variant="titleMd"
          as="h1"
        />

        <Box flex flexWrap gap={16}>
          <ResponsiveBox
            xs={
              <Button
                variant="secondary"
                tx="button.offers.my-qr-code"
                onClick={getQrCode}
                isLoading={offersLinkStatus === 'pending'}
                disabled={isOpen}
              />
            }
          >
            <Tooltip placement="left" titleTx="button.offers.my-qr-code">
              <SolidIconButton
                onClick={getQrCode}
                icon="qr-code"
                disabled={isOpen}
              />
            </Tooltip>
          </ResponsiveBox>

          <ResponsiveBox
            xs={
              <Button
                to="/offers/create-offer"
                tx="button.offers.new-offer"
                variant="primary"
              />
            }
          >
            <Tooltip placement="left" titleTx="button.offers.new-offer">
              <SolidIconButton
                to="/offers/create-offer"
                icon="plus-circle-outline"
              />
            </Tooltip>
          </ResponsiveBox>
        </Box>
      </Box>

      <SearchOffers />

      <OffersTable />
    </React.Fragment>
  );
}

function OffersToolbar() {
  return (
    <Toolbar backgroundColor="tabHeaderBackground" zIndex={1}>
      <ToolbarContentContainer flexJustify="between">
        <BreadCrumbs>
          <BreadCrumb tx="label.breadcrumbs.offers.offers" isLast />
        </BreadCrumbs>
      </ToolbarContentContainer>
    </Toolbar>
  );
}

function OffersSidebar() {
  const offersLink = useSelector(OffersLinkSelector.selectData);
  const departmentLogo = useSelector(DepartmentSelector.selectLogo);

  const offersLinkStatus = useSelector(OffersLinkSelector.selectStatus);

  const isLoading = offersLinkStatus === 'pending';
  const isOpen = !!offersLink || isLoading;

  const dispatch = useDispatch();

  const closeSidebar = () => {
    dispatch(offersActions.clearOffersLink());
  };

  const [logo, setLogo] = React.useState(
    departmentLogo?.thumbnail1024.url ?? '',
  );

  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleFileChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!e.target.files) {
      return;
    }

    const file = e.target.files[0];

    const reader = new FileReader();

    reader.onload = () => {
      setLogo(reader.result as string);
    };

    reader.readAsDataURL(file);
  };

  const reset = () => {
    setLogo(departmentLogo?.thumbnail1024.url ?? '');

    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const openFilePicker = () => {
    inputRef.current?.click();
  };

  return (
    <ExpandedQrCodeProvider
      footerLogoURL={assets.inAssociationWithOrbi}
      headerLogoURL={assets.redeemOffer}
      closeTx="button.close"
      value={offersLink ?? ''}
      logoURL={logo}
    >
      <ContentSidebar isOpen={isOpen} onClose={closeSidebar} width={400}>
        <ContentSidebarContentContainer>
          {isLoading && (
            <Box py={32} flex flexJustify="center">
              <Spinner />
            </Box>
          )}

          {!isLoading && (
            <Box flex flexDirection="column" gap={16}>
              <Box flex flexDirection="column" gap={4}>
                <Text
                  tx="label.offers.qr-code-sidebar.title"
                  variant="bodyLgBold"
                />
                <Text tx="label.offers.qr-code-sidebar.description" />
              </Box>

              <Box gap={8} flexAlign="center" flex>
                <input
                  accept={Object.values(FileType).join(',')}
                  type="file"
                  onChange={handleFileChange}
                  ref={inputRef}
                  hidden
                />

                {logo !== departmentLogo?.thumbnail1024.url ? (
                  <Button
                    variant="secondary"
                    onClick={reset}
                    tx="button.remove-logo"
                    icon="x-mark"
                  />
                ) : (
                  <Button
                    variant="secondary"
                    onClick={openFilePicker}
                    tx="button.change-logo"
                    icon="upload"
                  />
                )}
              </Box>

              <PrintQrCode
                expandTx="label.general.expand"
                printTx="label.general.print"
              />
            </Box>
          )}
        </ContentSidebarContentContainer>
      </ContentSidebar>
    </ExpandedQrCodeProvider>
  );
}

const welcomeToOffersModalSlides: ImageSliderModalSlide[] = [
  {
    type: 'image',
    src: assets.offersModal.offersModal1,
    alt: translate('label.offer-welcome-modal.slide-1-message'),
    descriptionElement: (
      <Box flex flexDirection="column" flexAlign="center" gap={4} py={16}>
        <Text
          variant="bodyMdBold"
          tx="label.offer-welcome-modal.slide-1-title"
          textAlign="center"
        />
        <Text
          variant="bodyMd"
          tx="label.offer-welcome-modal.slide-1-message"
          textAlign="center"
        />
      </Box>
    ),
  },
  {
    type: 'image',
    src: assets.offersModal.offersModal2,
    alt: translate('label.offer-welcome-modal.slide-2-message'),
    descriptionTx: 'label.offer-welcome-modal.slide-2-message',
  },
  {
    type: 'image',
    src: assets.offersModal.offersModal3,
    alt: translate('label.offer-welcome-modal.slide-3-message'),
    descriptionTx: 'label.offer-welcome-modal.slide-3-message',
  },
];

function WelcomeToOfferstModal() {
  const [isOpen, setIsOpen] = React.useState(
    !localStorage.getItem('offersModal'),
  );

  const handleClose = () => {
    setIsOpen(false);
    localStorage.setItem('offersModal', 'false');
  };

  return (
    <ImageSliderModal
      width={700}
      isOpen={isOpen}
      onClose={handleClose}
      slides={welcomeToOffersModalSlides}
      titleTx="label.offer-welcome-modal.title"
    />
  );
}

export function Offers() {
  return (
    <React.Fragment>
      <WelcomeToOfferstModal />

      <PageContainer>
        <OffersToolbar />

        <InnerPageContainer>
          <ContentContainer>
            <InnerContentContainer>
              <OffersContent />
            </InnerContentContainer>
          </ContentContainer>

          <OffersSidebar />
        </InnerPageContainer>
      </PageContainer>
    </React.Fragment>
  );
}
