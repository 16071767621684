import {
  GeneralApiResult,
  GeneralApiResultWithData,
  Pagination,
} from '@orbiapp/components';

import {
  ActivityPost,
  CreateActivityPost,
  UpdateActivityPost,
} from '../../../../../../models';
import { Logger } from '../../../../../logger';
import { apisauce } from '../../../../api';
import { getGeneralApiProblem } from '../../../../api.utils';
import { PostDto } from './posts.dto';
import {
  activityPostMapper,
  createActivityPostMapper,
  updateActivityPostMapper,
} from './posts.mappers';

export const createActivityPost = async (
  createActivityPost: CreateActivityPost,
): Promise<GeneralApiResultWithData<string>> => {
  const [activityKey, data] = createActivityPostMapper(createActivityPost);

  const res = await apisauce.post<{ postKey: string }, any>(
    `/v1/activities/${activityKey}/posts`,
    data,
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = res.data.postKey;

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('createActivityPost', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const updateActivityPost = async (
  updateActivityPost: UpdateActivityPost,
): Promise<GeneralApiResult> => {
  const [activityKey, activityPostKey, data] =
    updateActivityPostMapper(updateActivityPost);

  const res = await apisauce.put<void, any>(
    `/v1/activities/${activityKey}/posts/${activityPostKey}`,
    data,
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
};

export const getActivityPosts = async (params: {
  pagination: Pagination;
  activityKey: string;
}): Promise<GeneralApiResultWithData<ActivityPost[]>> => {
  const { activityKey, pagination } = params;

  const res = await apisauce.get<PostDto[], any>(
    `/v1/activities/${activityKey}/posts`,
    pagination,
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = res.data.map(activityPostMapper);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getActivityPosts', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};
